// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.MuiButtonBase-root.flow-button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px;
    color: $white;
    border-radius: 75px;
    font-size: 24px;
    min-width: 375px;
    max-width: 375px;
    min-height: 85px;
    -webkit-transition: .5s all;
    transition: .5s all;
    // width: 100%;
    @include respond-to('small') {
        min-width: 275px;
        padding: 0 0 0 15px;
        width: 85%;
        min-height: 65px;
        font-size: 22px;
    }
}

.MuiButtonBase-root.flow-button:hover {
    opacity: 0.75;
}

.flow-button-icon {
    font-size: 40px;
    min-width: 100px;
    @include respond-to('small') {
        min-width: 20vw;
    }
}

.MuiButton-label {
    white-space: nowrap;
}

.button-link:hover,
.button-link:active,
.button-link:link,
.button-link:visited {
    text-decoration: none;
}

.MuiButtonBase-root.optin-button.flow-button {
    padding: 0;
    justify-content: center;
}

.MuiButtonBase-root.offer__button_single {
    min-width: 275px;
    max-width: 275px;
    height: 90px;
    background-color: $medium-blue;
    font-size: 24px;
    border-radius: 90px;
    color: $white;
    line-height: 1;
    margin-top: 25px;
}

.MuiButtonBase-root.offer__button_single-text {
    display: flex;
}

.offer__button_single-subtitle {
    font-size: 15px;
    font-weight: 300;
}

.offer-button-icon {
    font-size: 2.2rem;
    margin-right: 20px;
}

.offer__button_multi {
    margin: 10px;
    min-width: calc(50% - 40px);
    min-height: 65px;
    border-radius: 8px;
    color: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;

    @include respond-to('small') {
        width: calc(100vw - 50px);
    }
}

.four-button_group {
    margin: 10px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
}

.button_multi-left {
    height: 65px;
    width:50px;
    background-color: $button-shade;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
}

.button_multi-right {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    font-weight: 400;
    padding: 0 10px;
}

.offer-multi_button-icon {
    font-size: 2.2rem;
    padding: 5px;
}

.MuiButton-contained.button-wrapper {
    min-width: 250px;
    width: 100%;
    max-width: 450px;
    min-height: 65px;
    padding: 0;
    margin: 10px;
    color: white;
    border-radius: 10px;
    line-height: 1.3;
    @include respond-to('small') {
        max-width: unset;
        width: unset;
    }
}

.four_button-item {
    width: 100%;
    padding: 0;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 65px;
    border-radius: 10px;
}

.button-bg__0 {
    background-color: rgb(24,159,229);
}
.button-bg__1 {
    background-color: rgb(24,188,85);
}
.button-bg__2 {
    background-color: rgb(247,153,49);
}
.button-bg__3 {
    background-color: rgb(229,67,147);
}

.button-icon-container {
    min-height: 65px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 50px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    font-size: 1.5rem;
    background-color: rgba(0,0,0,0.3);
}

.button-text-container {
    width: 75%;
    min-height: 65px;
    max-height: 65px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    font-size: 1rem;
    font-weight: 300;
    white-space: normal;
}