// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a {
  color: $text-primary;
  text-decoration: none;

  @include on-event {
    color: $text-color;
    // text-decoration: underline;
  }
}

img {
  width: 100%;
}

/* So that the mobile browser does not zoom in onFocus */
select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}

input[type=file] {
  width: 100%;
}

iframe {
  position: relative;
  // top: 80px;
}

button {
  border: none !important;
}

*:focus {
  outline:none !important
}